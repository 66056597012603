import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import OrderDetailModal from 'components/presentations/OrderDetailModal';
import formatCurrency from 'utils/formatCurrency';
import {
  // getTotalCartItemAmount,
  totalAmountOfCart,
  getTotalCartItemDiscount,
  totalDiscountOfCart,
} from 'utils/getCartAmount';
import isShowCartItemDetail from 'utils/isShowCartItemDetail';
import { getDefaultCartItem } from 'status/partial/cart';
import styles from './styles.js';

function getDeliveryButtonColor(type, orderType) {
  if (type === orderType) {
    return 'primary';
  }
  return 'default';
}

class CartContent extends React.PureComponent {
  state = {
    isOpenDetailModal: false,
    currentDish: getDefaultCartItem(),
  }

  openDetailModal = (cartItem) => {
    if (isShowCartItemDetail(cartItem)) {
      this.setState({
        currentDish: cartItem,
        isOpenDetailModal: true,
      });
    }
  }

  render() {
    const {
      classes,
      cart,
      deliveryAllow,
      setOrderTypeToPickUp,
      setOrderTypeToDelivery,
      orderinfo,
      onSubmit,
      removeDish,
      addDish,
      promotion,
    } = this.props;
    const { isOpenDetailModal, currentDish } = this.state;
    const totalPrice = formatCurrency(totalDiscountOfCart(cart, promotion, new Date()));
    const totalOriginPrice = formatCurrency(totalAmountOfCart(cart));

    return (
      <React.Fragment>
        <ListItem className={classes.head}>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          Indkøbskurv
        </ListItem>
        <Divider />
        <List>
          <ListItem className={classes.listItem}>
            <Grid container spacing={24}>
              {deliveryAllow &&
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color={getDeliveryButtonColor('delivery', orderinfo.order_type)}
                    className={classes.submit}
                    onClick={setOrderTypeToDelivery}
                  >
                    Udbringning
                  </Button>
                  {
                    /*
                      <Typography variant="caption" align="center">
                        Kr. 0.00 / ca. 45 min.
                      </Typography>
                    */
                  }
                </Grid>
              }
              <Grid item xs={deliveryAllow ? 6 : 12}>
                <Button
                  variant="outlined"
                  color={getDeliveryButtonColor('pick_up', orderinfo.order_type)}
                  className={classes.submit}
                  onClick={setOrderTypeToPickUp}
                >
                  Afhentning
                </Button>
                <Typography variant="caption" align="center">ca. 20 min.</Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              variant="raised"
              color="primary"
              className={classes.submit}
              onClick={onSubmit}
            >
              Gå til kassen
            </Button>
          </ListItem>
        </List>
        <Divider />
        <List component="nav">
          {
            cart.length > 0 ?
              cart.map((item, i) => (
                <ListItem
                  className={classes.listItem}
                  key={`sidebar_cart_${item.sku.id}_${item.sku.name}_${i}`}
                >
                  <ListItemText
                    className={classnames({
                      [classes.title]: true,
                      [classes.more]: isShowCartItemDetail(item),
                    })}
                    onClick={() => this.openDetailModal(item)}
                  >
                    {item.sku.name}
                  </ListItemText>
                  <ListItemIcon
                    className={classes.iconWrap}
                    onClick={() => removeDish(item)}
                  >
                    <RemoveCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary={item.count} className={classes.count} />
                  <ListItemIcon
                    className={classes.iconWrap}
                    onClick={() => addDish(item)}
                  >
                    <AddCircleIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${formatCurrency(getTotalCartItemDiscount(item, promotion, new Date()))} kr`}
                    className={classes.price}
                  />
                </ListItem>
              ))
              :
              <ListItem className={classes.listItem}>
                <ListItemText primary={'tom.'} className={classes.title} />
              </ListItem>
          }
        </List>
        <Divider />
        <List>
          <ListItem className={classes.listItem}>
            <ListItemText primary="Priser" />
            <ListItemText
              primary={
                totalPrice === totalOriginPrice ?
                  <span>DKK {totalOriginPrice} kr</span>
                  :
                  <del>DKK {totalOriginPrice} kr</del>
              }
              className={classes.price}
            />
          </ListItem>
          {
            totalPrice !== totalOriginPrice &&
            <ListItem className={classes.listItem}>
              <ListItemText primary="Rabat Priser" />
              <ListItemText
                className={classes.price}
              >
                DKK {totalPrice} kr
              </ListItemText>
            </ListItem>
          }
        </List>
        <Divider />
        <OrderDetailModal
          open={isOpenDetailModal}
          dish={currentDish}
          onClose={() => this.setState({ isOpenDetailModal: false })}
          promotion={promotion}
        />
      </React.Fragment>
    );
  }
}

CartContent.propTypes = {
  classes: PropTypes.shape().isRequired,
  cart: PropTypes.arrayOf(PropTypes.shape({
    sku: PropTypes.shape({
      properties: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
      })).isRequired,
    }),
    extras: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    attachProperties: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    count: PropTypes.number.isRequired,
  })),
  orderinfo: PropTypes.shape().isRequired,
  setOrderTypeToDelivery: PropTypes.func.isRequired,
  setOrderTypeToPickUp: PropTypes.func.isRequired,
  deliveryAllow: PropTypes.bool,
  removeDish: PropTypes.func.isRequired,
  addDish: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  promotion: PropTypes.arrayOf(PropTypes.shape()),
};

CartContent.defaultProps = {
  cart: [],
  orderinfo: {},
  onSubmit: () => null,
  deliveryAllow: true,
  promotion: [],
};

export default withStyles(styles)(CartContent);
