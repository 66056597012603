import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Footer from 'components/presentations/Footer';

class FooterWrapper extends React.PureComponent {
  render() {
    const { store } = this.props;
    return (
      <Footer
        email={store.main_email}
        address={`${store.street_name} ${store.house_number} ${store.floor_number}, ${store.post_number} ${store.city_name}`}
        cellphone={store.main_phone_number}
      />
    );
  }
}

FooterWrapper.propTypes = {
  store: PropTypes.shape().isRequired,
};

export default connect(
  state => ({
    store: state.store,
  }),
)(FooterWrapper);
