import { mediaLarge } from 'themes/mixins';

export default theme => ({
  wrapper: {
    display: 'none',
  },
  ...mediaLarge({
    wrapper: {
      display: 'block',
      boxShadow: theme.shadows[1],
    },
  }),
});
