export default {
  isEmail(value) {
    return /^([\w-_]+(?:\.[\w-_]+)*)@((?:[a-z0-9]+(?:-[a-zA-Z0-9]+)*)+\.[a-z]{2,6})$/i.test(value);
  },
  isPassword(value) {
    if (value == null) return false;
    return /^[0-9a-zA-Z]{8,}$/.test(value);
  },
  isCellphone(value) {
    return /^\d{6,}$/.test(value);
  },
  isUserName(value) {
    if (value == null) return false;
    return value.trim().length > 0;
  },
  isNotEmpty(value) {
    if (value == null) return false;
    return value.trim().length > 0;
  },
};
