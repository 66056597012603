import React from 'react';
import ReactDom from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from 'themes/customization';
import { Provider } from 'react-redux';
import store from 'status/store';
import { message } from 'antd';
import qs from 'qs';
import { isEmpty } from 'lodash';
import * as Sentry from '@sentry/browser';
import fetchStore from 'apis/services/store';
import fetchConfiguration from 'apis/services/configuration';
import { initStoreSuccess } from 'status/partial/store';
import { fetchDeliveryTemplateSuccess } from 'status/partial/delivery';
import { initConfigurationSuccess } from 'status/partial/configuration';
import { resetCart } from 'status/partial/cart';
import { resetOrderinfo } from 'status/partial/orderinfo';
import { resetMenu } from 'status/partial/menu';
import { resetDiner } from 'status/partial/diner';
import { resetAuth } from 'status/partial/auth';
import getDeliveryTemplate from 'apis/services/delivery';
import IOSiFrameFixed from 'components/utils/IOSiFrameFixed';
import RouteComponents from './RouteComponents';

message.config({
  top: 72,
  maxCount: 3,
});

async function startApp() {
  let storeId = 0;
  if (process.env.NODE_ENV === 'development') {
    storeId = qs.parse(window.location.search.slice(1)).store;
    if (isEmpty(storeId) || storeId == null) {
      storeId = 1;
    }
  }
  if (process.env.NODE_ENV === 'production') {
    storeId = qs.parse(window.location.search.slice(1)).store;
    if (isEmpty(storeId) || storeId == null) {
      message.error('have no store id.');
    }
  }

  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: 'https://a0ed34a1fc9340a196602990dbfddd28@sentry.escreen-host.com/2',
      release: process.env.RELEASE_VERSION,
    });
  }

  storeId = parseInt(storeId, 10);
  const beforeStore = sessionStorage.getItem('store');
  if (beforeStore != null && JSON.parse(beforeStore).id !== storeId) {
    store.dispatch(resetCart());
    store.dispatch(resetOrderinfo());
    store.dispatch(resetMenu());
    store.dispatch(resetDiner());
    store.dispatch(resetAuth());
  }

  const storeInfo = await fetchStore({ id: storeId });
  store.dispatch(initStoreSuccess(storeInfo.data));

  const deliveryTemplate = await getDeliveryTemplate({ store: storeId });
  store.dispatch(fetchDeliveryTemplateSuccess(deliveryTemplate));

  const configuration = await fetchConfiguration({ store: storeId });
  store.dispatch(initConfigurationSuccess(configuration));

  const root = document.getElementById('root');
  ReactDom.render(
    (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <React.Fragment>
            <CssBaseline />
            <IOSiFrameFixed root={root} />
            <RouteComponents />
          </React.Fragment>
        </MuiThemeProvider>
      </Provider>
    ),
    root,
  );
}

startApp();
