import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

class ScrollToTarget extends React.PureComponent {
  render() {
    const { classes, onClick } = this.props;
    return (
      <IconButton className={classes.wrapper} onClick={onClick}>
        <ArrowUpwardIcon />
      </IconButton>
    );
  }
}

ScrollToTarget.propTypes = {
  classes: PropTypes.shape().isRequired,
  onClick: PropTypes.func,
};

ScrollToTarget.defaultProps = {
  onClick: () => null,
};

export default withStyles(styles)(ScrollToTarget);
