const SET_DINER = Symbol('SET_DINER');
const RESET_DINER = Symbol('RESET_DINER');
const SAVE_DINER = Symbol('SAVE_DINER');

export const actionTypes = {
  SET_DINER,
  RESET_DINER,
  SAVE_DINER,
};

function getInitalDiner() {
  const localDiner = localStorage.getItem('diner');
  if (localDiner != null) {
    return JSON.parse(localDiner);
  }
  return {};
}

export const initialState = getInitalDiner();

export const setDiner = auth => ({ type: SET_DINER, payload: auth });
export const saveDiner = () => ({ type: SAVE_DINER });
export const resetDiner = () => ({ type: RESET_DINER });

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DINER:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_DINER:
      return {};
    case SAVE_DINER:
      return state;
    default:
      return state;
  }
};
