import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import Footer from 'components/containers/FooterWrapper';
import Topbar from 'components/presentations/Topbar';
import OrderDetailPreviewModal from 'components/presentations/OrderDetailPreviewModal';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { get, isEmpty } from 'lodash';
import qs from 'qs';
import formatCurrency from 'utils/formatCurrency';
import isShowCartItemDetail from 'utils/isShowCartItemDetail';
import { findTargetPromotion } from 'utils/promotionTools';
import { timeformat } from 'utils/timeTools';
import { getOrderByCode, createWebSocket } from 'apis/services/order';
import styles, { tableCellStyles } from './styles';

function stringifyOrderStatus(status, time) {
  const orderStatusMap = {
    waiting: 'Vi behandler denne order.',
    accepted: `Vi har modtaget og accepteret din ordre.Den vil være klar til den ${timeformat(time)}.`,
    closed: 'Din ordre er blevet afvist.',
  };
  if (!isEmpty(orderStatusMap[status])) {
    return orderStatusMap[status];
  }
  return status;
}

const CustomTableCell = withStyles(tableCellStyles)(TableCell);

function targetDiscountValue(orderSkuPrmotions, created) {
  const params = orderSkuPrmotions.map(item => ({
    ...item,
    joined_merchandises: [-1],
  }));
  const target = findTargetPromotion(-1, params, moment(created).toDate());
  if (isEmpty(target)) {
    return 1;
  }
  return target.detail.value;
}

function getUnitSkuItemPrice(orderSkuItem, created) {
  const extraAmount = orderSkuItem.orderextra_set.reduce(
    (x, y) => x + (y.price * y.count),
    0,
  );
  const discountValue = targetDiscountValue(
    get(orderSkuItem, 'ordermerchandisepromotion_set', []),
    created,
  );
  return (orderSkuItem.price * discountValue) + extraAmount;
}

function getSkuItemPrice(orderSkuItem, created) {
  return getUnitSkuItemPrice(orderSkuItem, created) * get(orderSkuItem, 'count', 0);
}


function getOrderAmount(order) {
  if (isEmpty(order.ordersku_set)) {
    return 0;
  }
  return order.ordersku_set.reduce(
    (x, y) => x + getSkuItemPrice(y, order.created),
    0,
  );
}

class OrderPreviewer extends React.PureComponent {
  state = {
    isOpenDetailModal: false,
    currentDish: {
      price: 0,
      count: 0,
      orderextra_set: [],
      ordermerchandisepromotion_set: [],
      properties: [],
      attach_properties: [],
      store_category_id: 0,
      store_category_name: '',
    },
    currentOrder: {
      delivery_amount: 0,
      ordersku_set: [],
      created: '1970-01-01T00:00:00.0Z',
    },
  }

  componentDidMount = () => {
    const { store, location } = this.props;
    const { search } = location;
    const searchObj = qs.parse(search.slice(1));
    const orderCode = get(searchObj, 'order_code', '');
    getOrderByCode({
      store: store.id,
      order_code: orderCode,
    }).then((data) => {
      if (!isEmpty(data)) {
        this.setState({
          currentOrder: data,
        });
        this.listenNetOrder(data.id);
      }
    });
  }

  componentWillUnmount = () => {
    if (!isEmpty(this.socket)) {
      this.socket.close();
    }
  }

  listenNetOrder = (currentId) => {
    const { store } = this.props;
    this.socket = createWebSocket(store.id);
    this.socket.onmessage = (e) => {
      const data = JSON.parse(e.data);
      const message = get(data, 'message', {});
      if (message.id === currentId && message.method === 'PATCH') {
        this.setState({
          currentOrder: message.content,
        });
      }
    };
  }

  openDetailModal = (cartItem) => {
    if (isShowCartItemDetail(cartItem)) {
      this.setState({
        currentDish: cartItem,
        isOpenDetailModal: true,
      });
    }
  }

  render() {
    const { classes, location } = this.props;
    const { isOpenDetailModal, currentDish, currentOrder } = this.state;
    const orderskuSet = get(currentOrder, 'ordersku_set', []);
    const { search } = location;
    const searchObj = qs.parse(search.slice(1));
    const orderCode = get(searchObj, 'order_code', '');
    return (
      <div className={classes.wrapper}>
        <Topbar />
        <div className={classes.content}>
          <div className={classes.inner}>
            <Typography className={classes.title} variant="display1" align="center" gutterBottom>Din Order Info.</Typography>
            <Typography variant="subheading" align="center" gutterBottom>Order Nr. { orderCode }</Typography>
            {
              !isEmpty(currentOrder) &&
                <Typography variant="subheading" align="center" gutterBottom>
                  {stringifyOrderStatus(currentOrder.status, currentOrder.plan_datetime)}
                </Typography>
            }
            <Paper className={classes.root}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <CustomTableCell>Navn</CustomTableCell>
                    <CustomTableCell numeric>Antal</CustomTableCell>
                    <CustomTableCell numeric>Prisr</CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderskuSet.map(n => (
                    <TableRow className={classes.row} key={n.id}>
                      <CustomTableCell
                        component="th"
                        scope="row"
                        className={classNames({
                          [classes.more]: isShowCartItemDetail(n),
                        })}
                        onClick={() => this.openDetailModal(n)}
                      >
                        {n.name}
                      </CustomTableCell>
                      <CustomTableCell numeric>{n.count}</CustomTableCell>
                      <CustomTableCell numeric>
                        {
                          formatCurrency(
                            getSkuItemPrice(n, currentOrder.created),
                          )
                        }
                      </CustomTableCell>
                    </TableRow>
                  ))}
                  <TableRow className={classes.row}>
                    <CustomTableCell component="th" scope="row">
                      Delivery Amount
                    </CustomTableCell>
                    <CustomTableCell numeric />
                    <CustomTableCell numeric>
                      { formatCurrency(currentOrder.delivery_amount) }
                    </CustomTableCell>
                  </TableRow>
                  <TableRow className={classes.row}>
                    <CustomTableCell component="th" scope="row">
                      Total
                    </CustomTableCell>
                    <CustomTableCell numeric />
                    <CustomTableCell numeric>
                      {
                        formatCurrency(
                          getOrderAmount(currentOrder) + currentOrder.delivery_amount,
                        )
                      }
                    </CustomTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </div>
        </div>
        <Footer />
        <OrderDetailPreviewModal
          open={isOpenDetailModal}
          dish={currentDish}
          onClose={() => this.setState({ isOpenDetailModal: false })}
          unitPrice={getUnitSkuItemPrice(currentDish, currentOrder.created)}
        />
      </div>
    );
  }
}

OrderPreviewer.propTypes = {
  classes: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  store: PropTypes.shape().isRequired,
};

export default connect(
  state => ({
    location: state.routing.location,
    store: state.store,
  }),
)(withStyles(styles)(OrderPreviewer));
