import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LockOpen from '@material-ui/icons/LockOpen';
import Button from '@material-ui/core/Button';
import className from 'classnames';
import { Field, reduxForm, propTypes, Form } from 'redux-form';
import EmailField from 'components/presentations/Fields/EmailField';
import PasswordField from 'components/presentations/Fields/PasswordField';
import ConfirmPasswordField from 'components/presentations/Fields/ConfirmPasswordField';
import CircularProgress from '@material-ui/core/CircularProgress';
import validator from 'utils/validator';
import styles from './styles';

function SetPassword(props) {
  const { classes, handleSubmit, submitting } = props;
  return (
    <div>
      <div className={className(classes.padding, classes.middle)}>
        <LockOpen className={classes.title} />
      </div>
      <Form noValidate onSubmit={handleSubmit} autoComplete="off">
        <Field
          name="email"
          component={EmailField}
        />
        <Field
          name="origin_password"
          component={params => <PasswordField label="nuværende kodeord" {...params} />}
        />
        <Field
          name="password1"
          component={params => <PasswordField label="nyt kodeord" {...params} />}
        />
        <Field
          name="password2"
          component={ConfirmPasswordField}
        />
        <div className={classes.padding}>
          {
            submitting ?
              <Button color="primary" variant="raised" fullWidth disabled>
                <CircularProgress size={20} />
              </Button>
              :
              <Button color="primary" variant="raised" fullWidth type="submit">Indsend</Button>
          }
        </div>
      </Form>
    </div>
  );
}

SetPassword.propTypes = {
  ...propTypes,
  classes: PropTypes.shape().isRequired,
};

export default reduxForm({
  form: 'password',
  validate(values) {
    const result = {};

    if (!validator.isPassword(values.password1)) {
      result.password1 = 'Adgangskode længde større end eller lig med ';
    }

    if (!validator.isEmail(values.email)) {
      result.email = 'Indtast venligst den rigtige email';
    }

    if (values.password1 !== values.password2) {
      result.password2 = 'Uoverensstemmende med adgangskode';
    }

    if (!validator.isPassword(values.origin_password)) {
      result.origin_password = 'Adgangskode længde større end eller lig med';
    }
    return result;
  },
})(withStyles(styles)(SetPassword));
