import getMenu from 'apis/services/menu';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';

const FETCH_MENU = Symbol('FETCH_MENU');
const FETCH_MENU_SUCCESS = Symbol('FETCH_MENU_SUCCESS');
const RESET_MENU = Symbol('RESET_MENU');
export const actionTypes = {
  FETCH_MENU,
  FETCH_MENU_SUCCESS,
  RESET_MENU,
};

export const getDefaultMenuItem = () => ({
  merchandisespec_set: [],
  extras: [],
  properties: [],
  attach_properties: [],
  property_values: [],
  attach_property_values: [],
  store_category: {},
});

function initState() {
  const sessionMenu = sessionStorage.getItem('menu');
  if (sessionMenu != null) {
    return JSON.parse(sessionMenu);
  }
  return [];
}
export const initialState = initState();

export const fetchMenu = params => ({ type: FETCH_MENU, payload: params });
export const fetchMenuSuccess = response => ({
  type: FETCH_MENU_SUCCESS,
  payload: response,
});
export const resetMenu = () => ({ type: RESET_MENU });

export const fetchMenuEpic = (action$, store$) =>
  action$.ofType(FETCH_MENU).mergeMap((action) => {
    const menu = store$.value.menu;
    if (isEmpty(menu)) {
      return Observable.fromPromise(getMenu(action.payload))
        .map(response => fetchMenuSuccess(response));
    }
    return Observable.of(fetchMenuSuccess(menu));
  });

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MENU_SUCCESS:
      return [...action.payload];
    case RESET_MENU:
      return [];
    default:
      return state;
  }
};
