import { colors } from 'themes/variables';
import { mediaLarge } from 'themes/mixins';

export default theme => ({
  paper: {
    margin: 0,
  },
  title: {
    padding: theme.spacing.unit * 2,
    borderBottom: `1px solid ${theme.palette.divider}`,
    textAlign: 'center',
  },
  content: {
    maxWidth: '94vw',
    padding: theme.spacing.unit * 2,
  },
  iconWrap: {
    marginRight: 0,
    color: colors.primary,
    cursor: 'pointer',
  },
  iconWrapDisabled: {
    marginRight: 0,
    color: colors.secondary,
    cursor: 'pointer',
  },
  extrasWrap: {
    padding: 0,
  },
  extrasTable: {
    '& thead th': {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
    '& td': {
      padding: 5,
    },
    '& th': {
      padding: 5,
    },
    '& td:last-child': {
      paddingRight: 5,
    },
    '& th:last-child': {
      paddingRight: 5,
    },
  },
  propertyWrap: {
    marginBottom: theme.spacing.unit,
    '& button': {
      marginRight: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
  },
  attach_properties: {
    width: '100%',
    marginBottom: theme.spacing.unit * 6,
  },
  actions: {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    margin: 0,
  },
  totalPrice: {
    flexGrow: 1,
    color: colors.primary,
  },
  ...mediaLarge({
    content: {
      minWidth: 650,
    },
  }),
});
