import { actionTypes as dinerActionTypes } from 'status/partial/diner';

const saveAuthToLocal = store => next => (action) => {
  const result = next(action);
  const state = store.getState();
  switch (action.type) {
    case dinerActionTypes.SAVE_DINER:
      localStorage.setItem('diner', JSON.stringify(state.diner));
      break;
    case dinerActionTypes.RESET_DINER:
      localStorage.removeItem('diner');
      break;
    default:
      break;
  }
  return result;
};

export default saveAuthToLocal;
