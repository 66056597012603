const SET_ORDER_INFO = Symbol('SET_ORDER_INFO');
const MERGE_ORDER_INFO = Symbol('MERGE_ORDER_INFO');
const RESET_ORDER_INFO = Symbol('REMOVE_ORDER_INFO');
const SET_ORDER_TYPE_TO_DELIVERY = Symbol('SET_ORDER_TYPE_TO_DELIVERY');
const SET_ORDER_TYPE_TO_PICKUP = Symbol('SET_ORDER_TYPE_TO_PICKUP');

export const actionTypes = {
  SET_ORDER_INFO,
  MERGE_ORDER_INFO,
  RESET_ORDER_INFO,
  SET_ORDER_TYPE_TO_DELIVERY,
  SET_ORDER_TYPE_TO_PICKUP,
};

function createInitialState() {
  const orderinfo = sessionStorage.getItem('orderinfo');
  if (orderinfo != null) {
    return JSON.parse(orderinfo);
  }
  return {
    contact_name: '',
    contact_phone: '',
    contact_email: '',
    city_name: '',
    street_name: '',
    house_number: '',
    floor_number: '',
    post_number: '',
    delivery_amount: 0,
    country_id: 1,
    store_id: 0,
    plan_datetime: undefined,
    order_type: 'pick_up', // 'pick_up', 'delivery'
    description: '',
    // status: 'holding', // waiting, holding
  };
}

export const initialState = createInitialState();

export const orderTypeEnum = ['pick_up', 'delivery'];
export const setOrderinfo = (key, value) => ({ type: SET_ORDER_INFO, payload: { key, value } });
export const setOrderTypeToPickUp = () => ({ type: SET_ORDER_TYPE_TO_PICKUP, payload: {} });
export const setOrderTypeToDelivery = () => ({ type: SET_ORDER_TYPE_TO_DELIVERY, payload: {} });
export const mergeOrderinfo = delivery => ({ type: MERGE_ORDER_INFO, payload: delivery });
export const resetOrderinfo = () => ({ type: RESET_ORDER_INFO });

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDER_INFO:
      return {
        ...state,
        ...{ [action.payload.key]: action.payload.value },
      };
    case MERGE_ORDER_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_ORDER_INFO:
      return createInitialState();
    case SET_ORDER_TYPE_TO_PICKUP:
      return {
        ...state,
        ...{ order_type: 'pick_up' },
        ...{ delivery_amount: 0 },
      };
    case SET_ORDER_TYPE_TO_DELIVERY:
      return {
        ...state,
        ...{ order_type: 'delivery' },
      };
    default:
      return state;
  }
};
