
import { monochrome, footerHeight, colors } from 'themes/variables';
import { wrapper } from 'themes/mixins';

export default theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    ...wrapper(),
  },
  content: {
    minHeight: `calc(100vh - ${footerHeight}px)`,
    backgroundColor: monochrome.white,
    paddingRight: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    margin: theme.spacing.unit * 2,
    marginTop: 56 + (theme.spacing.unit * 2),
  },
  title: theme.mixins.toolbar,
  formWrapper: {
    width: '100%',
    alignSelf: 'center',
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  price: {
    textAlign: 'right',
    padding: 0,
  },
  light: {
    color: colors.focus,
  },
  orderTitle: {
    backgroundColor: monochrome.white,
    // color: colors.dark,
  },
  originPrice: {
    marginRight: theme.spacing.unit,
    color: colors.secondary,
  },
  more: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});
