import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import withMobileDialog from '@material-ui/core/withMobileDialog';
import styles from './styles';

class OfflineModal extends React.PureComponent {
  handleClose = () => {
    this.props.onClose();
  }

  render() {
    const { open, classes, email, phone } = this.props;

    return (
      <Dialog
        open={open}
        onClose={() => null}
      >
        <DialogTitle>Varsel</DialogTitle>
        <DialogContent className={classes.content}>
          <Typography>The order site temporarily closed.</Typography>
          <Typography>You can try again later or contact us by phone or email.</Typography>
          <br />
          <Typography>Email: { email }</Typography>
          <Typography>Tlf: { phone }</Typography>
        </DialogContent>
        {/*
        <DialogActions>
          <Button
            onClick={this.handleClose}
            color="primary"
            autoFocus
          >
            Okay
          </Button>
        </DialogActions>
        */}
      </Dialog>
    );
  }
}

OfflineModal.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

OfflineModal.defaultProps = {
  open: false,
  onClose: () => null,
};

export default connect()(withMobileDialog()(withStyles(styles)(OfflineModal)));
