
import { colors } from 'themes/variables';

export default theme => ({
  title: {
    fontSize: '500%',
    color: colors.primary,
  },
  padding: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  middle: {
    textAlign: 'center',
  },
  light: {
    color: colors.primary,
    textDecoration: 'none',
  },
});
