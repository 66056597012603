import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Footer from 'components/containers/FooterWrapper';
import Topbar from 'components/presentations/Topbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import BookingForm from 'components/containers/forms/Booking';
import { mergeOrderinfo } from 'status/partial/orderinfo';
import { push } from 'react-router-redux';
import { modifyDinerDeliveryInfo, createDinerDeliveryInfo } from 'apis/services/signin';
import { setDiner, saveDiner } from 'status/partial/diner';
import { isEmpty, pick, get, find } from 'lodash';
import { message } from 'antd';
import {
  totalDiscountOfCart,
} from 'utils/getCartAmount';
import styles from './styles';

function getDistanceMatrix(service, params) {
  return new Promise((resolve, reject) => {
    service.getDistanceMatrix(params, (response, status) => {
      if (status !== 'OK') {
        reject(status);
      } else {
        resolve(response);
      }
    });
  });
}

function initialValues(auth, diner) {
  const hasinitValue = !isEmpty(auth) && !isEmpty(diner);
  const hasDefaultDinerDeliveryInfo = !isEmpty(diner) &&
    !isEmpty(diner.dinerdeliveryinfomation_set);
  let result = {};
  if (hasinitValue) {
    result = {
      contact_name: diner.name,
      contact_phone: diner.phone_number,
      contact_email: auth.user.email,
    };
  }
  if (hasDefaultDinerDeliveryInfo) {
    const defaultDeliveryInfo = diner.dinerdeliveryinfomation_set[0];
    result = {
      ...result,
      ...{
        house_number: defaultDeliveryInfo.house_number,
        floor_number: defaultDeliveryInfo.floor_number,
        post_number: defaultDeliveryInfo.post_number,
        street_name: defaultDeliveryInfo.street_name,
        city_name: defaultDeliveryInfo.city_name,
        contact_name: defaultDeliveryInfo.contact_name,
        contact_phone: defaultDeliveryInfo.contact_phone,
        contact_email: defaultDeliveryInfo.contact_email,
      },
    };
  }
  return result;
}

class Booking extends React.PureComponent {
  onNext = async (values) => {
    // const auth = this.props.auth;
    const {
      orderinfo,
    } = this.props;

    if (orderinfo.order_type === 'delivery') {
      const isOverDeliveryStartAmount = this.isOverDeliveryStartAmount(values);
      if (!isOverDeliveryStartAmount.result) {
        message.error(isOverDeliveryStartAmount.reason);
        return;
      }
      const isCanDelivery = await this.isCanDelivery(values);
      if (!isCanDelivery) {
        message.error('uden levering afstand');
        return;
      }
      this.props.mergeOrderinfo(values);
    } else {
      this.props.mergeOrderinfo(pick(values, ['contact_name', 'contact_phone', 'contact_email']));
    }

    this.setDinerDeliveryInfo(values);

    this.props.dispatch(push({
      pathname: '/time',
    }));
  }

  setDinerDeliveryInfo = (values) => {
    const { diner } = this.props;
    if (isEmpty(diner)) {
      return;
    }

    const defaultDinerDeliveryInfo = get(diner, 'dinerdeliveryinfomation_set', [])[0];
    let dinerDeliveryInfoRes;
    if (!isEmpty(defaultDinerDeliveryInfo)) {
      dinerDeliveryInfoRes = modifyDinerDeliveryInfo({
        ...values,
        ...{
          id: defaultDinerDeliveryInfo.id,
        },
      });
    } else {
      dinerDeliveryInfoRes = createDinerDeliveryInfo({
        ...values,
        ...{
          diner: diner.id,
        },
      });
    }
    dinerDeliveryInfoRes.then((res) => {
      const newDiner = {
        ...diner,
        dinerdeliveryinfomation_set: [res.data],
      };
      this.props.setDiner(newDiner);
      this.props.saveDiner(newDiner);
    });
  }

  isCanDelivery = async (orderinfo) => {
    /* global google:true */
    message.loading();
    const { store, delivery } = this.props;
    const origin = `${store.street_name} ${store.house_number}, ${store.post_number} ${store.city_name}`;
    const destination = `${orderinfo.street_name} ${orderinfo.house_number} ${orderinfo.post_number}`;
    const service = new google.maps.DistanceMatrixService();
    const response = await getDistanceMatrix(service, {
      origins: [origin],
      destinations: [destination],
      travelMode: 'DRIVING',
      unitSystem: google.maps.UnitSystem.METRIC,
      avoidHighways: false,
      avoidTolls: false,
    });
    let results = {};
    try {
      results = response.rows[0].elements[0].distance.value / 1000;
    } catch (err) {
      return false;
    }
    const targetArea = find(
      delivery.deliveryarea_set,
      area => area.post_number === orderinfo.post_number,
    );
    if (targetArea == null) {
      return false;
    }
    const radius = targetArea.deliveryradius_set.sort((x, y) => x.radius - y.radius);
    const targetRadius = radius.filter(item => item.radius >= results);
    message.destroy();
    return targetRadius.length > 0;
  }

  isOverDeliveryStartAmount = (orderinfo) => {
    const { cart, promotion, delivery } = this.props;
    const cartAmount = totalDiscountOfCart(
      cart,
      promotion,
      new Date(),
    );
    const targetArea = find(
      delivery.deliveryarea_set,
      area => area.post_number === orderinfo.post_number,
    );
    if (targetArea == null) {
      return {
        reason: 'uden levering afstand',
        result: false,
      };
    }
    if (cartAmount < targetArea.start_amount) {
      return {
        reason: `Bestillingsbeløbet minimum ${targetArea.start_amount} kr er påkrævet for levering til din placering. nu er det kun ${cartAmount} kr.`,
        result: false,
      };
    }
    return {
      reason: '',
      result: true,
    };
  }

  render() {
    const { classes, auth, store, diner, orderinfo, delivery } = this.props;
    return (
      <main className={classes.wrapper}>
        <Topbar />
        <div className={classes.content}>
          <div className={classes.formWrapper}>
            <BookingForm
              initialValues={initialValues(auth, diner)}
              onSubmit={this.onNext}
              email={isEmpty(auth)}
              diner={diner}
              storeInfo={store}
              delivery={orderinfo.order_type === 'delivery'}
              areas={delivery.deliveryarea_set}
            />
          </div>
        </div>
        <Footer />
      </main>
    );
  }
}


Booking.propTypes = {
  classes: PropTypes.shape().isRequired,
  mergeOrderinfo: PropTypes.func.isRequired,
  setDiner: PropTypes.func.isRequired,
  saveDiner: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  auth: PropTypes.shape().isRequired,
  diner: PropTypes.shape().isRequired,
  store: PropTypes.shape().isRequired,
  orderinfo: PropTypes.shape().isRequired,
  delivery: PropTypes.shape().isRequired,
  cart: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  promotion: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default connect(
  state => ({
    auth: state.auth,
    diner: state.diner,
    store: state.store,
    orderinfo: state.orderinfo,
    cart: state.cart,
    delivery: state.delivery,
    promotion: state.promotion,
  }),
  dispatch => ({
    ...bindActionCreators({
      mergeOrderinfo,
      setDiner,
      saveDiner,
    }, dispatch),
    dispatch,
  }),
)(withStyles(styles)(Booking));
