import React from 'react';
import Footer from 'components/containers/FooterWrapper';
import Topbar from 'components/presentations/Topbar';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import TimeForm from 'components/containers/forms/Time';
import { setOrderinfo } from 'status/partial/orderinfo';
import { push } from 'react-router-redux';
import moment from 'moment';
import { isEmpty, get } from 'lodash';
import extractOpenAndCloseTime from 'utils/extractOpenAndCloseTime';
// import { isDatetimeInWeeklySchedule } from 'utils/timeTools';
import styles from './styles';

function getTimeValue(date, openTime, closeTime) {
  if (openTime == null || closeTime == null) {
    return '';
  }
  const target = moment().toDate();
  const start = moment(`${date} ${openTime}`).toDate();
  const end = moment(`${date} ${closeTime}`).toDate();
  if (date === moment().format('YYYY-MM-DD')) {
    if (target > start && target < end) {
      return 'Hurtigst Muligt';
    }
  }
  return '';
}

function getDateValue(currentDate, time, endTime) {
  let now = moment();
  if (!isEmpty(time)) {
    now = moment(`${currentDate} ${time}`);
  }
  const closeTime = moment(`${currentDate} ${endTime}`);
  const delta = now - closeTime;
  if (delta <= 0) {
    return moment().format('YYYY-MM-DD');
  }
  return moment().add(1, 'd').format('YYYY-MM-DD');
}

function splitTime(originTime) {
  const time = moment(originTime).format('YYYY-MM-DD HH:mm:ss');
  const index = time.indexOf(' ');
  const first = time.slice(0, index);
  const last = time.slice(index + 1);
  return [first, last];
}

class Booking extends React.PureComponent {
  onNext = (values) => {
    const defaultDateTime = moment().format('YYYY-MM-DD HH:mm:ss');
    const [defaultDate, defaultTime] = splitTime(defaultDateTime);
    if (
      !isEmpty(get(values, 'date', '')) &&
      !isEmpty(get(values, 'time', '')) &&
      get(values, 'time', '') !== 'Hurtigst Muligt'
    ) {
      this.props.setOrderinfo(
        'plan_datetime',
        moment(`${get(values, 'date', defaultDate)} ${get(values, 'time', defaultTime)}`).toDate().toISOString(),
      );
    } else {
      this.props.setOrderinfo(
        'plan_datetime',
        undefined,
      );
    }
    this.props.setOrderinfo('description', values.description);

    /*
    if (isDatetimeInWeeklySchedule(
      new Date(),
      this.props.store.opening_hours))
    {
      this.props.setOrderinfo('status', 'waiting');
    } else {
      this.props.setOrderinfo('status', 'holding');
    }
    */

    this.props.dispatch(push({
      pathname: '/purchase',
    }));
  }

  render() {
    const { classes, orderinfo, store } = this.props;
    const [date, time] = !isEmpty(orderinfo.plan_datetime) ?
      splitTime(orderinfo.plan_datetime)
      :
      [moment().format('YYYY-MM-DD'), ''];
    const openAndCloseTime = extractOpenAndCloseTime(store.opening_hours);
    return (
      <main className={classes.wrapper}>
        <Topbar />
        <div className={classes.content}>
          <div className={classes.formWrapper}>
            <TimeForm
              onSubmit={this.onNext}
              initialValues={{
                date: !isEmpty(orderinfo.plan_datetime) ? date : getDateValue(
                  date,
                  time,
                  openAndCloseTime.endtime,
                ),
                time: !isEmpty(orderinfo.plan_datetime) ? '' : getTimeValue(
                  date,
                  openAndCloseTime.starttime,
                  openAndCloseTime.endtime,
                ),
                description: orderinfo.description,
              }}
            />
          </div>
        </div>
        <Footer />
      </main>
    );
  }
}

Booking.propTypes = {
  classes: PropTypes.shape().isRequired,
  setOrderinfo: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  orderinfo: PropTypes.shape().isRequired,
  store: PropTypes.shape().isRequired,
};

export default connect(
  state => ({
    orderinfo: state.orderinfo,
    store: state.store,
  }),
  dispatch => ({
    ...bindActionCreators({ setOrderinfo }, dispatch),
    dispatch,
  }),
)(withStyles(styles)(Booking));
