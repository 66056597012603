import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Footer from 'components/containers/FooterWrapper';
import Topbar from 'components/presentations/Topbar';
import OrderDetailModal from 'components/presentations/OrderDetailModal';
import PropTypes from 'prop-types';
import qs from 'qs';
import { withStyles } from '@material-ui/core/styles';
import { setOrderinfo } from 'status/partial/orderinfo';
import { replace, push } from 'react-router-redux';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import classNames from 'classnames';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import PlaceIcon from '@material-ui/icons/LocationOn';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { message } from 'antd';
import { isEmpty, first, find } from 'lodash';
import { newOrder, paidCallbackUrl } from 'apis/services/order';
import { Link } from 'react-router-dom';
import {
  // getTotalCartItemAmount,
  totalAmountOfCart,
  // getTotalCartItemDiscount,
  totalDiscountOfCart,
  getUnitCartItemDiscount,
  getUnitCartItemAmount,
} from 'utils/getCartAmount';
import formatCurrency from 'utils/formatCurrency';
import isShowCartItemDetail from 'utils/isShowCartItemDetail';
import { simplifyTargetCartItem, getDefaultCartItem } from 'status/partial/cart';

import styles from './styles';

function mapCartToSkuList(cart) {
  return cart.map(item => ({
    sku_id: item.sku.id,
    count: item.count,
    extras: item.extras.map(ext => ({
      extra_id: ext.extra.id,
      count: ext.count,
    })),
    store_category_id: item.storeCategory.id,
    attach_properties: item.attachProperties.map(prop => ({
      key: prop.key,
      value: prop.value,
    })),
  }));
}

function getCartItemKey(cartItem) {
  const target = simplifyTargetCartItem(cartItem);
  return JSON.stringify(target);
}

function getDistanceMatrix(service, params) {
  return new Promise((resolve, reject) => {
    service.getDistanceMatrix(params, (response, status) => {
      if (status !== 'OK') {
        reject(status);
      } else {
        resolve(response);
      }
    });
  });
}

function getDeliveryWayText(value, time) {
  if (value === 'pick_up') {
    return (
      <React.Fragment>
        <ListItemIcon><PlaceIcon /></ListItemIcon>
        <ListItemText><Typography>Afhentning</Typography></ListItemText>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <ListItemIcon><DirectionsCarIcon /></ListItemIcon>
      <ListItemText><Typography>Udbringning { time }</Typography></ListItemText>
    </React.Fragment>
  );
}

function distanceBeyond() {
  message.error('uden levering afstand');
}

class Purchase extends React.PureComponent {
  state = {
    agree: false,
    canDelivery: true,
    distance: 0,
    isOpenDetailModal: false,
    submitting: false,
    currentDish: getDefaultCartItem(),
  }

  componentDidMount() {
    const { orderinfo } = this.props;
    if (orderinfo.order_type === 'delivery') {
      this.getDeliveryPrice();
    }
  }

  getDeliveryPrice = async () => {
    /* global google:true */
    message.loading();
    const { orderinfo, store, delivery } = this.props;
    const origin = `${store.street_name} ${store.house_number}, ${store.post_number} ${store.city_name}`;
    const destination = `${orderinfo.street_name} ${orderinfo.house_number} ${orderinfo.post_number}`;
    const service = new google.maps.DistanceMatrixService();
    const response = await getDistanceMatrix(service, {
      origins: [origin],
      destinations: [destination],
      travelMode: 'DRIVING',
      unitSystem: google.maps.UnitSystem.METRIC,
      avoidHighways: false,
      avoidTolls: false,
    });
    const info = response.rows[0].elements[0];
    let results = 0;
    if (info.status === 'OK') {
      results = info.distance.value / 1000;
    } else {
      this.setState({
        canDelivery: false,
      });
      distanceBeyond();
      return;
    }
    message.success(results);
    message.destroy();

    const targetArea = find(
      delivery.deliveryarea_set,
      area => area.post_number === orderinfo.post_number,
    );
    if (targetArea == null) {
      distanceBeyond();
      return;
    }

    const radius = targetArea.deliveryradius_set.sort((x, y) => x.radius - y.radius);
    const targetRadius = radius.filter(item => item.radius >= results);
    if (targetRadius === 0) {
      distanceBeyond();
      return;
    }
    const targetRadiusPrice = first(targetRadius);
    this.props.setOrderinfo('delivery_amount', targetRadiusPrice.amount);
    this.setState({
      distance: results,
    });
  }

  getPaymentWindow = (orderCode) => {
    /* global PaymentWindow:true */
    const { store, cart, orderinfo, promotion } = this.props;
    let merchantNumber = '';
    if (process.env.NODE_ENV === 'development') {
      merchantNumber = '8030657';
    }
    if (process.env.NODE_ENV === 'production') {
      merchantNumber = store.merchant_number;
    }
    const finalAmount = totalDiscountOfCart(
      cart,
      promotion,
      new Date(),
    ) + orderinfo.delivery_amount;
    const paymentwindow = new PaymentWindow({
      merchantnumber: merchantNumber,
      amount: Math.round(finalAmount * 100),
      currency: 'DKK',
      windowstate: '3',
      orderid: orderCode,
      paymentcollection: '1',
      instantcapture: '1',
      accepturl: `${window.location.href.replace('#/purchase', '#/done')}`,
      callbackurl: paidCallbackUrl,
      instantcallback: '1',
    });
    return paymentwindow;
  }

  openDetailModal = (cartItem) => {
    if (isShowCartItemDetail(cartItem)) {
      this.setState({
        currentDish: cartItem,
        isOpenDetailModal: true,
      });
    }
  }

  payInCash = async () => {
    try {
      this.setState({
        submitting: true,
      });
      message.loading('', 30);
      const newOrderResponse = await newOrder(this.composeOrder('cash'));
      message.destroy();
      this.setState({
        submitting: false,
      });
      this.props.setOrderinfo('order_code', newOrderResponse.data.order_code);
      this.props.dispatch(push({
        pathname: '/done',
      }));
    } catch (error) {
      message.destroy();
      this.setState({
        submitting: false,
      });
      message.error('Betaling mislykkedes');
    }
  }

  agree = (value) => {
    this.setState({
      agree: value,
    });
  }

  composeOrder = (paymentType) => {
    const { cart, orderinfo, store, diner } = this.props;
    const dinerInfo = isEmpty(diner) ? {} : { diner: diner.id };
    const params = JSON.parse(JSON.stringify({
      sku_list: [
        ...mapCartToSkuList(cart),
      ],
      ...orderinfo,
      ...dinerInfo,
      payment_type: paymentType,
      store_id: store.id,
    }));
    return params;
  }

  payInCard = async () => {
    const { routing, orderinfo } = this.props;
    const orderId = qs.parse(routing.location.search.slice(1)).id;
    if (orderId != null) {
      const paymentwindow = this.getPaymentWindow(orderinfo.order_code);
      paymentwindow.open();
      return;
    }

    try {
      this.setState({
        submitting: true,
      });
      message.loading('', 30);
      const newOrderResponse = await newOrder(this.composeOrder('card'));
      this.setState({
        submitting: false,
      });
      message.destroy();
      const orderNumber = newOrderResponse.data.order_code;
      this.props.setOrderinfo('order_code', orderNumber);
      this.props.dispatch(replace({
        search: `?id=${newOrderResponse.data.id}`,
      }));
      const paymentwindow = this.getPaymentWindow(orderNumber);
      paymentwindow.open();
    } catch (error) {
      this.setState({
        submitting: false,
      });
      message.destroy();
      message.error(JSON.stringify(error.response.data));
    }
  }

  renderDeliveryInfo = () => {
    const { orderinfo, classes } = this.props;
    const { canDelivery, distance } = this.state;
    if (orderinfo.order_type === 'delivery') {
      if (canDelivery) {
        return (
          <ListItem className={classes.listItem}>
            <ListItemText>
              <Typography>Delivery Amount</Typography>
            </ListItemText>
            <ListItemText className={classes.price}>
              <Typography>
                ({distance}km) {formatCurrency(orderinfo.delivery_amount)} DKK
              </Typography>
            </ListItemText>
          </ListItem>
        );
      }
      return (
        <ListItem className={classes.listItem}>
          <ListItemText>
            <Typography>Delivery Amount</Typography>
          </ListItemText>
          <ListItemText className={classNames(classes.price, classes.light)}>
            <Typography className={classes.light}>uden levering afstand</Typography>
          </ListItemText>
        </ListItem>
      );
    }
    return null;
  }

  render() {
    const { classes, cart, store, orderinfo, promotion } = this.props;
    const { agree, canDelivery, isOpenDetailModal, currentDish, submitting } = this.state;
    const totalPrice = totalAmountOfCart(cart);
    const discountedPrice = totalDiscountOfCart(cart, promotion, new Date());
    return (
      <main className={classes.wrapper}>
        <Topbar />
        <Paper className={classes.content}>
          <ListItem className={classes.title}>
            <ListItemIcon><LocalGroceryStoreIcon /></ListItemIcon>
            <ListItemText>Hvordan vil du betale</ListItemText>
          </ListItem>
          <Divider />
          <div className={classes.formWrapper}>
            <Grid container spacing={24}>
              <Grid item xs={12} md={4}>
                <div>
                  {!isEmpty(store.merchant_number) &&
                    <ExpansionPanel>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <ListItemIcon><CreditCardIcon /></ListItemIcon>
                        <ListItemText className={classes.heading}>Betal med kort</ListItemText>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className={classes.details}>
                        <div className={classes.helper}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                checked={agree}
                                onChange={() => this.agree(!agree)}
                              />
                            }
                            label="jeg bekræfter at jeg har læst "
                          />
                          <Typography variant="caption" align="center">
                            <Link to={{ pathname: '/term' }} className={classes.link}>Købsbetingelser</Link>
                          </Typography>
                        </div>
                      </ExpansionPanelDetails>
                      <Divider />
                      <ExpansionPanelActions>
                        {
                          (agree && canDelivery && !submitting) &&
                            <Button color="primary" variant="raised" onClick={this.payInCard}>
                              Gennemfør betaling
                            </Button>
                        }
                        {
                          (agree && canDelivery && submitting) &&
                            <Button color="primary" disabled>
                              <CircularProgress size={16} />
                            </Button>
                        }
                        {
                          (!agree && canDelivery) &&
                            <Button
                              color="default"
                              onClick={() => message.warning('Læs venligst og accepterer vilkårene for brug')}
                            >
                              Gennemfør betaling
                            </Button>
                        }
                        {
                          (!agree && !canDelivery) &&
                            <Button
                              color="default"
                              onClick={() => message.warning('Læs venligst og accepterer vilkårene for brug')}
                            >
                              Gennemfør betaling
                            </Button>
                        }
                        {
                          (agree && !canDelivery) &&
                            <Button color="default" onClick={distanceBeyond}>
                              Gennemfør betaling
                            </Button>
                        }
                      </ExpansionPanelActions>
                    </ExpansionPanel>
                  }
                  <ExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <ListItemIcon><AttachMoneyIcon /></ListItemIcon>
                      <ListItemText className={classes.heading}>Betal kontant</ListItemText>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.details}>
                      <span />
                    </ExpansionPanelDetails>
                    <Divider />
                    <ExpansionPanelActions>
                      {
                        (canDelivery && !submitting) &&
                          <Button color="primary" variant="raised" onClick={this.payInCash}>
                            Gennemfør betaling
                          </Button>
                      }
                      {
                        (!canDelivery && !submitting) &&
                          <Button color="default" variant="raised" onClick={distanceBeyond}>
                            Gennemfør betaling
                          </Button>
                      }
                      {
                        (canDelivery && submitting) &&
                          <Button color="primary" disabled>
                            <CircularProgress size={16} />
                          </Button>
                      }
                    </ExpansionPanelActions>
                  </ExpansionPanel>

                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2} className={classes.orderTitle}>
                          <Typography variant="title" align="center" className={classes.orderTitle}>
                            Din ordre
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        cart.map(item => (
                          <TableRow key={getCartItemKey(item)}>
                            <TableCell
                              className={classNames({
                                [classes.title]: true,
                                [classes.more]: isShowCartItemDetail(item),
                              })}
                              onClick={() => this.openDetailModal(item)}
                            >
                              {item.sku.name}
                            </TableCell>
                            <TableCell className={classes.price} numeric>
                              {
                                getUnitCartItemDiscount(item, promotion, new Date()) !==
                                getUnitCartItemAmount(item) &&
                                  <small className={classes.originPrice}>
                                    <del>DKK {formatCurrency(getUnitCartItemAmount(item))}</del>
                                    {' X '} <span className={classes.light}>{item.count}</span>
                                  </small>
                              }
                              DKK {
                                formatCurrency(
                                  getUnitCartItemDiscount(item, promotion, new Date()),
                                )
                              }
                              <small>
                                {' X '} <span className={classes.light}>{item.count}</span>
                              </small>
                            </TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                  <Divider />
                  <List>
                    {
                      this.renderDeliveryInfo()
                    }
                    <ListItem className={classes.listItem}>
                      {
                        totalPrice !== discountedPrice ?
                          <ListItemText primary="Rabat Priser" />
                          :
                          <ListItemText primary="Total Priser" />
                      }
                      <ListItemText
                        primary={`DKK ${formatCurrency(discountedPrice + orderinfo.delivery_amount)}`}
                        className={classes.price}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.listItem}>
                      <ListItemText align="center">
                        <Typography>
                          {store.address}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.listItem}>
                      {
                        getDeliveryWayText(orderinfo.order_type, orderinfo.plan_time)
                      }
                    </ListItem>
                  </List>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </Paper>
        <Footer />
        <OrderDetailModal
          open={isOpenDetailModal}
          dish={currentDish}
          onClose={() => this.setState({ isOpenDetailModal: false })}
          promotion={promotion}
        />
      </main>
    );
  }
}


Purchase.propTypes = {
  classes: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  setOrderinfo: PropTypes.func.isRequired,
  orderinfo: PropTypes.shape().isRequired,
  store: PropTypes.shape().isRequired,
  diner: PropTypes.shape().isRequired,
  delivery: PropTypes.shape().isRequired,
  routing: PropTypes.shape().isRequired,
  cart: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  promotion: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default connect(
  state => ({
    orderinfo: state.orderinfo,
    cart: state.cart,
    store: state.store,
    diner: state.diner,
    routing: state.routing,
    delivery: state.delivery,
    promotion: state.promotion,
  }),
  dispatch => ({
    ...bindActionCreators({
      setOrderinfo,
    }, dispatch),
    dispatch,
  }),
)(withStyles(styles)(Purchase));
