import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { Field, reduxForm, propTypes, Form } from 'redux-form';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import EmailField from 'components/presentations/Fields/EmailField';
import NameField from 'components/presentations/Fields/NameField';
import TelephoneField from 'components/presentations/Fields/TelephoneField';
import className from 'classnames';
import validator from 'utils/validator';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styles from './styles';

// street name
function VejField({ input, meta }) {
  return (
    <TextField
      error={meta.submitFailed && meta.error != null}
      helperText={meta.submitFailed && meta.error ? meta.error : ''}
      placeholder="Vej"
      margin="normal"
      fullWidth
      {...input}
    />
  );
}

VejField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
};

// house number
function HusnummerField({ input, meta }) {
  return (
    <TextField
      error={meta.submitFailed && meta.error != null}
      helperText={meta.submitFailed && meta.error ? meta.error : ''}
      placeholder="Husnummer"
      margin="normal"
      fullWidth
      {...input}
    />
  );
}

HusnummerField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
};

// floor number
function EtageField({ input, meta }) {
  return (
    <TextField
      error={meta.submitFailed && meta.error != null}
      helperText={meta.submitFailed && meta.error ? meta.error : ''}
      placeholder="Etage (valgfri)"
      margin="normal"
      fullWidth
      {...input}
    />
  );
}

EtageField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
};

function CityField({ input, meta }) {
  return (
    <TextField
      error={meta.submitFailed && meta.error != null}
      helperText={meta.submitFailed && meta.error ? meta.error : ''}
      label="By eller område"
      margin="normal"
      fullWidth
      {...input}
    />
  );
}

CityField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
};

CityField.defaultProps = {
  val: '',
};

function PostnummerField({ input, meta, areas }) {
  return (
    <FormControl fullWidth>
      <InputLabel>Postnummer</InputLabel>
      <Select
        error={meta.submitFailed && meta.error != null}
        fullWidth
        {...input}
      >
        {
          areas.map(area => (
            <MenuItem value={area.post_number} key={`area_${area.post_number}`}>
              {area.post_number}
            </MenuItem>
          ))
        }
      </Select>
      {
        (meta.submitFailed && meta.error) &&
        <FormHelperText error>{meta.error}</FormHelperText>
      }
    </FormControl>
  );
}

PostnummerField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  areas: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

PostnummerField.defaultProps = {
  areas: [],
};

class Booking extends React.PureComponent {
  render() {
    const { classes, handleSubmit, email, delivery, initialValues, areas } = this.props;
    return (
      <div>
        <Typography variant="title" className={className(classes.padding, classes.middle, classes.label)}>
          Ordredetaljer
        </Typography>
        {
          email ?
            <Typography className={className(classes.padding, classes.middle)}>
              <Link className={classes.light} to={{ pathname: '/signup' }}>Opret Konto</Link>, modtage flere fordele.
            </Typography>
            :
            <Typography className={className(classes.padding, classes.middle)}>
              Ikke { initialValues.contact_name }?
              <Link className={classes.light} to={{ pathname: '/signin' }}> Skift konto </Link>.
            </Typography>
        }
        <Form
          noValidate
          onSubmit={handleSubmit}
        >
          <Field
            name="contact_name"
            component={NameField}
          />
          <Field
            name="contact_phone"
            component={TelephoneField}
          />

          {
            email &&
              <Field
                name="contact_email"
                component={EmailField}
              />
          }

          {
            delivery &&
              <React.Fragment>
                <Typography variant="subheading" className={className(classes.padding, classes.label)}>
                  Adresse
                </Typography>

                <Field
                  name="street_name"
                  component={VejField}
                />

                <Field
                  name="house_number"
                  component={HusnummerField}
                />

                <Field
                  name="floor_number"
                  component={EtageField}
                />
                {/*
                  <Typography
                    variant="subheading"
                    className={className(
                      classes.padding,
                      classes.label,
                    )}
                  >
                    Postnummer
                  </Typography>
                */}
                <Field
                  name="post_number"
                  component={PostnummerField}
                  areas={areas}
                />
                <Field
                  name="city_name"
                  component={CityField}
                />
              </React.Fragment>
          }

          <div className={className(classes.padding, classes.submit)}>
            <Button color="primary" variant="raised" fullWidth type="submit">Fortsaet</Button>
          </div>
        </Form>
      </div>
    );
  }
}

Booking.propTypes = {
  ...propTypes,
  classes: PropTypes.shape().isRequired,
  values: PropTypes.shape().isRequired,
  email: PropTypes.bool,
  delivery: PropTypes.bool,
  areas: PropTypes.arrayOf(PropTypes.shape()),
  storeInfo: PropTypes.shape().isRequired,
  diner: PropTypes.shape().isRequired,
};

Booking.defaultProps = {
  email: false,
  delivery: false,
  areas: [],
};

export default reduxForm({
  form: 'booking',
  validate(values, props) {
    const result = {};
    if (!validator.isUserName(values.contact_name)) {
      result.contact_name = 'Navn er et must';
    }

    if (!validator.isCellphone(values.contact_phone)) {
      result.contact_phone = 'Indtast venligst det rigtige telefonnummer';
    }

    if (!validator.isEmail(values.contact_email) && props.email) {
      result.contact_email = 'Indtast korrekt email';
    }

    if (props.delivery) {
      if (!validator.isNotEmpty(values.city_name)) {
        result.city_name = 'Udfyld byens oplysninger';
      }

      if (!validator.isNotEmpty(values.street_name)) {
        result.street_name = 'Indtast venligst gaden';
      }

      if (!validator.isNotEmpty(values.house_number)) {
        result.house_number = 'Indtast venligst nummeret på huset';
      }

      if (!validator.isNotEmpty(values.post_number)) {
        result.post_number = 'Vælg venligst postnummer';
      }
    }
    return result;
  },
})(connect(state => ({ values: state.form.booking.values }))(withStyles(styles)(Booking)));
