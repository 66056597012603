import { actionTypes as promotionActionTypes } from 'status/partial/promotion';

const savePromotionToSession = store => next => (action) => {
  const result = next(action);
  const state = store.getState();
  switch (action.type) {
    case promotionActionTypes.FETCH_MERCHANDISE_PROMOTION_SUCCESS:
      sessionStorage.setItem('promotion', JSON.stringify(state.promotion));
      break;
    case promotionActionTypes.RESET_MERCHANDISE_PROMOTION:
      sessionStorage.removeItem('promotion');
      break;
    default:
      break;
  }
  return result;
};

export default savePromotionToSession;
