import React from 'react';
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import Index from 'views/Index';
import Signin from 'views/Signin';
import Signup from 'views/Signup';
import Booking from 'views/Booking';
import Time from 'views/Time';
import Purchase from 'views/Purchase';
import Term from 'views/Term';
import BookSuccess from 'views/BookSuccess';
import OrderPreviewer from 'views/OrderPreviewer';
import Password from 'views/Password';
import history from './history';

const routes = [
  {
    path: '/',
    component: Index,
    exact: true,
  },
  {
    path: '/signin',
    component: Signin,
  },
  {
    path: '/signup',
    component: Signup,
  },
  {
    path: '/set_password',
    component: Password,
  },
  {
    path: '/shipping',
    component: Booking,
  },
  {
    path: '/time',
    component: Time,
  },
  {
    path: '/purchase',
    component: Purchase,
  },
  {
    path: '/term',
    component: Term,
  },
  {
    path: '/done',
    component: BookSuccess,
  },
  {
    path: '/preview_order',
    component: OrderPreviewer,
  },
];


export const RouteWithSubRoutes = route => (
  <Route
    path={route.path}
    exact={route.exact}
    render={props => (
      <route.component {...props} routes={route.routes} />
    )}
  />
);

const RouteComponents = () => (
  <ConnectedRouter history={history}>
    <React.Fragment>
      {routes.map(route => <RouteWithSubRoutes key={route.path} {...route} />)}
    </React.Fragment>
  </ConnectedRouter>
);

export default RouteComponents;
