import { combineEpics } from 'redux-observable';
import { fetchCategoryEpic } from './category';
import { fetchDeliveryTemplateEpic } from './delivery';
import { fetchMenuEpic } from './menu';
import { fetchPromotionEpic } from './promotion';

const rootEpic = combineEpics(
  fetchCategoryEpic,
  fetchDeliveryTemplateEpic,
  fetchMenuEpic,
  fetchPromotionEpic,
);

export default rootEpic;
