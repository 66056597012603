import moment from 'moment';

export default function extractOpenAndCloseTime(openingHours) {
  const day = moment().day();
  const weekMap = {
    0: 'Sun',
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat',
  };
  const key = weekMap[day];
  return openingHours[key];
}
