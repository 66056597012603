import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import className from 'classnames';
import { Field, reduxForm, propTypes, Form } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import { range } from 'lodash';
import { connect } from 'react-redux';
import validator from 'utils/validator';
import extractOpenAndCloseTime from 'utils/extractOpenAndCloseTime';
import styles from './styles';

function getStartDate(endTime = '22:00:00', currentTime, currentDate) {
  const closeTime = moment(`${currentDate} ${endTime}`);
  const now = moment(`${currentDate} ${currentTime}`);
  const delta = now - closeTime;
  if (delta <= 0) {
    return moment();
  }
  return moment().add(1, 'd');
}

function DateField({ input, meta, endTime, currentTime, currentDate }) {
  const long = 14;
  return (
    <TextField
      error={meta.submitFailed && meta.error != null}
      helperText={meta.submitFailed && meta.error ? meta.error : ''}
      select
      label="Dato"
      fullWidth
      margin="normal"
      {...input}
    >
      {
        range(long).map((item) => {
          const time = getStartDate(endTime, currentTime, currentDate).add(item, 'd').format('YYYY-MM-DD');
          return (
            <MenuItem value={time} key={`date_${time}`}>
              { time }
            </MenuItem>
          );
        })
      }
    </TextField>
  );
}

DateField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  endTime: PropTypes.string,
  currentTime: PropTypes.string.isRequired,
  currentDate: PropTypes.string.isRequired,
};

DateField.defaultProps = {
  endTime: '22:00:00',
};

function TimeField({ meta, input, startTime, endTime, opening, currentDate, currentTime }) {
  let start = moment(`${currentDate} ${startTime}`);
  if (opening) {
    start = moment(`${currentDate} ${currentTime}`);
  }
  const end = moment(`${currentDate} ${endTime}`);
  const delta = end - start;
  const step = 50 * 60 * 1000;
  const timeRange = Array(Math.floor(delta / step)).fill(1);
  return (
    <TextField
      error={meta.submitFailed && meta.error != null}
      helperText={meta.submitFailed && meta.error ? meta.error : ''}
      select
      label="tid"
      fullWidth
      margin="normal"
      {...input}
    >
      {
        opening &&
          <MenuItem value={'Hurtigst Muligt'}>
            Hurtigst Muligt
          </MenuItem>
      }
      {
        timeRange.map(() => {
          const time = start.add(step, 'ms').format('HH:mm:ss');
          return (
            <MenuItem value={time} key={`time_${time}`}>
              { time }
            </MenuItem>
          );
        })
      }
    </TextField>
  );
}

TimeField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  currentTime: PropTypes.string.isRequired,
  currentDate: PropTypes.string.isRequired,
  opening: PropTypes.bool,
};

TimeField.defaultProps = {
  startTime: '08:00:00',
  endTime: '18:00:00',
  opening: true,
};

function DescriptionField({ input }) {
  return (
    <TextField
      multiline
      rows="4"
      fullWidth
      {...input}
    />
  );
}

DescriptionField.propTypes = {
  input: PropTypes.shape().isRequired,
};

function isOpening(date, openTime, closeTime) {
  if (date !== moment().format('YYYY-MM-DD')) {
    return false;
  }
  if (openTime == null || closeTime == null) {
    return false;
  }
  const target = moment().toDate();
  const start = moment(`${date} ${openTime}`).toDate();
  const end = moment(`${date} ${closeTime}`).toDate();
  if (target > start && target < end) {
    return true;
  }
  return false;
}

class Time extends React.PureComponent {
  constructor(props) {
    super(props);
    const now = moment();
    this.currentDate = now.format('YYYY-MM-DD');
    this.currentTime = now.format('HH:mm:ss');
  }

  render() {
    const { classes, handleSubmit, values, store } = this.props;
    const startAndEndTime = extractOpenAndCloseTime(store.opening_hours);
    return (
      <div>
        <Typography
          variant="title"
          align="center"
          className={className(classes.padding, classes.title, classes.label)}
        >
          Bekræft din samlingstid
        </Typography>
        <Form noValidate onSubmit={handleSubmit} autoComplete="off">
          <Field
            name="date"
            component={DateField}
            startTime={startAndEndTime.starttime}
            endTime={startAndEndTime.endtime}
            currentTime={this.currentTime}
            currentDate={this.currentDate}
          />
          <Field
            name="time"
            component={TimeField}
            startTime={startAndEndTime.starttime}
            endTime={startAndEndTime.endtime}
            opening={isOpening(values.date, startAndEndTime.starttime, startAndEndTime.endtime)}
            currentTime={this.currentTime}
            currentDate={this.currentDate}
          />
          <Typography variant="caption" className={className(classes.padding, classes.label)}>
            Har du allergi eller andet kostbehov? Efterlad en kommentar til restauranten
          </Typography>
          <Field
            name="description"
            component={DescriptionField}
          />
          <div className={classes.bottom}>
            <Button color="primary" variant="raised" fullWidth type="submit">Gå til betaling</Button>
          </div>
        </Form>
      </div>
    );
  }
}

Time.propTypes = {
  ...propTypes,
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.shape().isRequired,
  values: PropTypes.shape().isRequired,
  store: PropTypes.shape().isRequired,
};


export default reduxForm({
  form: 'time',
  initialValues: {
    date: moment().format('YYYY-MM-DD'),
  },
  validate(values) {
    const result = {};

    if (!validator.isNotEmpty(values.date)) {
      result.date = 'Vælg venligst en dato';
    }

    if (!validator.isNotEmpty(values.time)) {
      result.time = 'Vælg venligst tid';
    }

    return result;
  },
})(connect(state => ({
  values: state.form.time.values,
  store: state.store,
}))(withStyles(styles)(Time)));
