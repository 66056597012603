const INIT_CONFIGURATION_SUCCESS = Symbol('INIT_CONFIGURATION_SUCCESS');
export const actionTypes = {
  INIT_CONFIGURATION_SUCCESS,
};

function createInitialState() {
  return {};
}

export const initialState = createInitialState();

export const initConfigurationSuccess = payload => ({
  type: INIT_CONFIGURATION_SUCCESS,
  payload,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT_CONFIGURATION_SUCCESS:
      return { ...action.payload };
    default:
      return state;
  }
};
