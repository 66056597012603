
import { colors, monochrome } from 'themes/variables';
import { mediaLarge } from 'themes/mixins';

export default theme => ({
  wrapper: {
    display: 'none',
  },
  ...mediaLarge({
    wrapper: {
      display: 'block',
      fontSize: '150%',
      boxShadow: theme.shadows[1],
      backgroundColor: monochrome.white,
      color: colors.primary,
      position: 'fixed',
      bottom: theme.spacing.unit * 2,
      right: theme.spacing.unit * 2,
    },
  }),
});
