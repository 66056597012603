import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import styles from './styles';

class Topbar extends React.PureComponent {
  state = {
    anchorEl: null,
  };

  handleMenu = event => this.setState({ anchorEl: event.currentTarget });

  handleClose = () => this.setState({ anchorEl: null });

  render() {
    const {
      classes,
      onMenuClick,
      onCartClick,
      cart,
      menu,
      auth,
      diner,
      cartIcon,
      store,
      position,
    } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div className={classes.root}>
        <AppBar position={position} color="inherit">
          <Toolbar>
            {
              menu &&
                <IconButton
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="Menu"
                  onClick={onMenuClick}
                >
                  <MenuIcon />
                </IconButton>
            }
            {
              store.logo == null || isEmpty(store.logo) ?
                <Typography variant="title" color="inherit" className={classes.flex}>
                  <Link to={{ pathname: '/' }} className={classes.logoWrapper}>
                    { store.name }
                  </Link>
                </Typography>
                :
                <Typography variant="title" color="inherit" className={classes.flex}>
                  <Link to={{ pathname: '/' }} className={classes.logoWrapper}>
                    <img src={store.logo} className={classes.logo} alt={store.name} />
                  </Link>
                </Typography>
            }
            {
              isEmpty(auth) ?
                <React.Fragment>
                  <Button color="inherit" component={Link} to={{ pathname: '/signin' }} >Login</Button>
                  <Button color="inherit" component={Link} to={{ pathname: '/signup' }} >Opret Konto</Button>
                </React.Fragment>
                :
                <div>
                  <IconButton
                    aria-owns={open ? 'menu-appbar' : null}
                    aria-haspopup="true"
                    onClick={this.handleMenu}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={open}
                    onClose={this.handleClose}
                  >
                    <MenuItem onClick={this.handleClose}>{ diner.name }</MenuItem>
                    <Link to={{ pathname: '/set_password' }}>
                      <MenuItem onClick={this.handleClose}>
                          skift kodeord
                      </MenuItem>
                    </Link>
                    <MenuItem onClick={this.props.logout}>Log ud</MenuItem>
                  </Menu>
                </div>
            }
            {
              cartIcon &&
                <IconButton
                  className={classes.cartButton}
                  color="inherit"
                  aria-label="Cart"
                  onClick={onCartClick}
                >
                  <ShoppingCartIcon className={classes.cartIcon} />
                  <Badge badgeContent={cart.reduce((count, item) => count + item.count, 0)} className={classes.badge} color="secondary">
                    <span />
                  </Badge>
                </IconButton>
            }
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

Topbar.propTypes = {
  classes: PropTypes.shape().isRequired,
  store: PropTypes.shape().isRequired,
  onMenuClick: PropTypes.func.isRequired,
  onCartClick: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  cart: PropTypes.arrayOf(PropTypes.shape()),
  cartIcon: PropTypes.bool,
  menu: PropTypes.bool,
  auth: PropTypes.shape(),
  diner: PropTypes.shape(),
  position: PropTypes.string,
};

Topbar.defaultProps = {
  classes: {},
  onMenuClick: () => null,
  onCartClick: () => null,
  logout: () => null,
  cart: [],
  menu: false,
  cartIcon: false,
  auth: {},
  diner: {},
  position: 'fixed',
};

export default withStyles(styles)(Topbar);
