import React from 'react';
import { connect } from 'react-redux';
import { resetAuth } from 'status/partial/auth';
import { resetDiner } from 'status/partial/diner';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { message } from 'antd';

export default function (Topbar) {
  function TopbarWrapper(props) {
    return (
      <Topbar
        {...props}
      />
    );
  }

  return connect(
    state => ({
      cart: state.cart,
      auth: state.auth,
      store: state.store,
      diner: state.diner,
    }),
    dispatch => ({
      resetAuth: bindActionCreators(resetAuth, dispatch),
      logout: () => {
        message.success('Succesfuld operation');
        dispatch(resetAuth());
        dispatch(resetDiner());
        dispatch(push({
          pathname: '/',
        }));
      },
    }),
  )(TopbarWrapper);
}
