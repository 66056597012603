import { isEmpty } from 'lodash';
import { findTargetPromotion } from 'utils/promotionTools';

export function getUnitCartItemAmount(skuItem) {
  const { sku, extras } = skuItem;
  const extraTotalPrice = extras.reduce(
    (total, item) => total + (item.extra.price * item.count),
    0,
  );
  const totalPrice = sku.price + extraTotalPrice;
  return totalPrice;
}

export function getTotalCartItemAmount(cartItem) {
  return getUnitCartItemAmount(cartItem) * cartItem.count;
}

export function totalAmountOfCart(cart) {
  return cart.reduce(
    (totalAmount, item) => getTotalCartItemAmount(item) + totalAmount,
    0,
  );
}

export function getUnitCartItemDiscount(skuItem, promotion, targetDatetime) {
  const { sku, extras } = skuItem;
  const extraTotalPrice = extras.reduce(
    (total, item) => total + (item.extra.price * item.count),
    0,
  );
  const targetPromotion = findTargetPromotion(
    sku.merchandise,
    promotion,
    targetDatetime,
  );
  let skuPrice = sku.price;
  if (!isEmpty(targetPromotion)) {
    skuPrice = sku.price * targetPromotion.detail.value;
  }
  const totalPrice = skuPrice + extraTotalPrice;
  return totalPrice;
}
export function getTotalCartItemDiscount(cartItem, promotion, targetDatetime) {
  return getUnitCartItemDiscount(cartItem, promotion, targetDatetime) * cartItem.count;
}

export function totalDiscountOfCart(cart, promotion, targetDatetime) {
  return cart.reduce(
    (totalDiscount, item) => getTotalCartItemDiscount(
      item,
      promotion,
      targetDatetime,
    ) + totalDiscount,
    0,
  );
}
