import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import className from 'classnames';
import { Field, reduxForm, propTypes, Form } from 'redux-form';
import EmailField from 'components/presentations/Fields/EmailField';
import PasswordField from 'components/presentations/Fields/PasswordField';
import NameField from 'components/presentations/Fields/NameField';
import TelephoneField from 'components/presentations/Fields/TelephoneField';
import ConfirmPasswordField from 'components/presentations/Fields/ConfirmPasswordField';
import CircularProgress from '@material-ui/core/CircularProgress';
import validator from 'utils/validator';
import { Link } from 'react-router-dom';
import styles from './styles';

function Signup(props) {
  const { classes, handleSubmit, submitting } = props;
  return (
    <div>
      <div className={className(classes.padding, classes.middle)}>
        <PersonAddIcon className={classes.title} />
      </div>
      <Form noValidate onSubmit={handleSubmit} autoComplete="off">
        <Field
          name="name"
          component={NameField}
        />
        <Field
          name="phone_number"
          component={TelephoneField}
        />
        <Field
          name="email"
          component={EmailField}
        />
        <Field
          name="password1"
          component={PasswordField}
        />
        <Field
          name="password2"
          component={ConfirmPasswordField}
        />
        <div className={classes.padding}>
          {
            submitting ?
              <Button color="primary" variant="raised" fullWidth disabled>
                <CircularProgress size={20} />
              </Button>
              :
              <Button color="primary" variant="raised" fullWidth type="submit">Opret konto</Button>
          }
        </div>
      </Form>
      <Typography className={className(classes.padding, classes.middle)}>
        Ved at oprette en konto accepterer du
        <Link to={{ pathname: '/term' }} className={classes.light}> vores vilkår og betingelser </Link>
        og <Link to={{ pathname: '/term' }} className={classes.light}>privatlivspolitik</Link>.
      </Typography>
    </div>
  );
}

Signup.propTypes = {
  ...propTypes,
  classes: PropTypes.shape().isRequired,
};

export default reduxForm({
  form: 'signup',
  validate(values) {
    const result = {};

    if (!validator.isUserName(values.name)) {
      result.name = 'Navn er et must';
    }

    if (!validator.isPassword(values.password1)) {
      result.password = 'Adgangskode længde større end eller lig med ';
    }

    if (!validator.isEmail(values.email)) {
      result.email = 'Indtast venligst den rigtige email';
    }

    if (values.password1 !== values.password2) {
      result.password2 = 'Uoverensstemmende med adgangskode';
    }

    if (!validator.isCellphone(values.phone_number)) {
      result.phone_number = 'Indtast venligst det rigtige telefonnummer';
    }

    return result;
  },
})(withStyles(styles)(Signup));
