const INIT_SUCCESS = Symbol('INIT_STORE_SUCCESS');
const REMOVE_STORE = Symbol('REMOVE_STORE');
export const actionTypes = {
  INIT_SUCCESS,
  REMOVE_STORE,
};

function createInitialState() {
  const store = sessionStorage.getItem('store');
  if (store != null) {
    return JSON.parse(store);
  }
  return {};
}

export const initialState = createInitialState();

export const initStoreSuccess = store => ({
  type: INIT_SUCCESS,
  payload: store,
});

export const removeStore = () => ({
  type: REMOVE_STORE,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT_SUCCESS:
      return { ...action.payload };
    case REMOVE_STORE:
      return {};
    default:
      return state;
  }
};
