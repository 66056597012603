import { findIndex, cloneDeep, isEqual } from 'lodash';

const ADD_DISH = Symbol('ADD_DISH');
const REMOVE_DISH = Symbol('REMOVE_DISH');
const RESET_CART = Symbol('RESET_CART');

export const actionTypes = {
  ADD_DISH,
  REMOVE_DISH,
  RESET_CART,
};

function initState() {
  const sessionCart = sessionStorage.getItem('cart');
  if (sessionCart != null) {
    return JSON.parse(sessionCart);
  }
  return [];
}

export const initialState = initState();
export const defaultScheme = {
  sku: {
    properties: [],
    price: 0,
  },
  storeCategory: {},
  extras: [],
  attachProperties: [],
  count: 1,
};
export const getDefaultCartItem = () => cloneDeep(defaultScheme);

export const addDish = dish => ({ type: ADD_DISH, payload: dish });
export const removeDish = dish => ({ type: REMOVE_DISH, payload: dish });
export const resetCart = () => ({ type: RESET_CART, payload: [] });

export function simplifyCart(cart) {
  return cart.map(item => ({
    sku: item.sku.id,
    extras: item.extras.map(ext => ({
      extra: ext.extra.id,
      count: ext.count,
    })).sort((x, y) => x.extra - y.extra),
    attachProperties: item.attachProperties.sort(
      (x, y) => x.id - y.id,
    ),
  }));
}

export function simplifyTargetCartItem(cartItem) {
  return {
    sku: cartItem.sku.id,
    extras: cartItem.extras.map(ext => ({
      extra: ext.extra.id,
      count: ext.count,
    })).sort((x, y) => x.extra - y.extra),
    attachProperties: cartItem.attachProperties.sort(
      (x, y) => x.id - y.id,
    ),
  };
}

// return the new cart item index if has the same item which already in the cart,
// or else return -1.
export function getCartItemIndex(cart, targetCartItem) {
  const simplifyCartResult = simplifyCart(cart);
  const simplifyTargetCartItemResult = simplifyTargetCartItem(targetCartItem);
  return findIndex(simplifyCartResult, item => isEqual(item, simplifyTargetCartItemResult));
}

const defaultSku = {
  price: 0,
  properties: [],
};

// map api menu item to cart item
export function mapItemToCart(
  sku = defaultSku,
  storeCategory = {},
  attachProperties = [],
  extras = [],
) {
  return {
    sku,
    storeCategory,
    attachProperties,
    extras,
    count: 1,
  };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_DISH: {
      const addTargetIndex = getCartItemIndex(state, action.payload);
      if (addTargetIndex >= 0) {
        const addNewState = cloneDeep(state);
        addNewState[addTargetIndex].count += 1;
        return addNewState;
      }
      return [...state, ...[{ ...defaultScheme, ...action.payload }]];
    }
    case REMOVE_DISH: {
      const removeTargetIndex = getCartItemIndex(state, action.payload);
      const removeNewState = cloneDeep(state);
      if (removeTargetIndex >= 0) {
        removeNewState[removeTargetIndex].count -= 1;
        if (removeNewState[removeTargetIndex].count > 0) {
          return removeNewState;
        }
      }
      removeNewState.splice(removeTargetIndex, 1);
      return removeNewState;
    }
    case RESET_CART:
      return [];
    default:
      return state;
  }
};
