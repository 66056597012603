import { actionTypes as cartTypes } from 'status/partial/cart';
import { actionTypes as orderinfoTypes } from 'status/partial/orderinfo';

const saveCartToSession = store => next => (action) => {
  const result = next(action);
  const state = store.getState();
  switch (action.type) {
    case cartTypes.ADD_DISH:
    case cartTypes.REMOVE_DISH:
      sessionStorage.setItem('cart', JSON.stringify(state.cart));
      break;
    case cartTypes.RESET_CART:
      sessionStorage.removeItem('cart');
      break;
    case orderinfoTypes.SET_ORDER_INFO:
    case orderinfoTypes.MERGE_ORDER_INFO:
    case orderinfoTypes.SET_ORDER_TYPE_TO_PICKUP:
    case orderinfoTypes.SET_ORDER_TYPE_TO_DELIVERY:
      sessionStorage.setItem('orderinfo', JSON.stringify(state.orderinfo));
      break;
    case orderinfoTypes.RESET_ORDER_INFO:
      sessionStorage.removeItem('orderinfo');
      break;
    default:
      break;
  }
  return result;
};

export default saveCartToSession;
