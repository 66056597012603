const API_V1 = 'api/v1';
const WS_API_V1 = 'ws_api/v1';

function getConfig(env) {
  // 开发环境 API 地址
  if (env === 'development') {
    return {
      // apiUrlV1: `https://testapi.nonoco.dk/${API_V1}`,
      // wsApiurlV1: `wss://testapi.nonoco.dk/${WS_API_V1}`,
      apiUrlV1: `https://preapi.nonoco.dk/${API_V1}`,
      wsApiurlV1: `wss://preapi.nonoco.dk/${WS_API_V1}`,
      // apiUrlV1: `http://127.0.0.1:8000/${API_V1}`,
      // wsApiurlV1: `ws://127.0.0.1:8000/${WS_API_V1}`,
    };
  }

  // 生产环境 API 地址
  if (env === 'production') {
    return {
      // apiUrlV1: `https://api.escreen-host.com/${API_V1}`,
      apiUrlV1: `https://nic.nonoco.dk/${API_V1}`,
      // wsApiurlV1: `wss://api.escreen-host.com/${WS_API_V1}`,
      wsApiurlV1: `wss://nic.nonoco.dk/${WS_API_V1}`,
    };
  }

  return {
    apiUrlV1: `/${API_V1}`,
    wsApiurlV1: `/${WS_API_V1}`,
  };
}

export default getConfig(process.env.NODE_ENV);
