import { actionTypes as storeActionTypes } from 'status/partial/store';

const saveStoreToSession = store => next => (action) => {
  const result = next(action);
  const state = store.getState();
  switch (action.type) {
    case storeActionTypes.INIT_SUCCESS:
      sessionStorage.setItem('store', JSON.stringify(state.store));
      break;
    case storeActionTypes.REMOVE_STORE:
      sessionStorage.removeItem('store');
      break;
    default:
      break;
  }
  return result;
};

export default saveStoreToSession;
