import { isEmpty, get, isEqual, findIndex } from 'lodash';

export default function (cartItem) {
  const sku = get(cartItem, 'sku', {});
  const properties = get(sku, 'properties', [{}]);
  const extras = get(cartItem, 'extras', []);
  const attachProperties = get(cartItem, 'attachProperties', []);
  const hasDefaultProp = findIndex(properties, prop => isEqual(prop, {
    key: 'default',
    value: 'default',
  })) >= 0;
  const isDefaultProp = hasDefaultProp && properties.length === 1;
  if (isEmpty(extras) && isDefaultProp && isEmpty(attachProperties)) {
    return false;
  }
  return true;
}
