import { actionTypes as menuActionTypes } from 'status/partial/menu';

const saveMenuToSession = store => next => (action) => {
  const result = next(action);
  const state = store.getState();
  switch (action.type) {
    case menuActionTypes.FETCH_MENU_SUCCESS:
      sessionStorage.setItem('menu', JSON.stringify(state.menu));
      break;
    case menuActionTypes.RESET_MENU:
      sessionStorage.removeItem('menu');
      break;
    default:
      break;
  }
  return result;
};

export default saveMenuToSession;
