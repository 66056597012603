
export default theme => ({
  bottom: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30,
  },
  title: {
    marginBottom: 20,
  },
  padding: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  label: {
    marginTop: 20,
  },
});
