import auth from './auth';
import diner from './diner';
import category from './category';
import store from './store';
import cart from './cart';
import orderinfo from './orderinfo';
import delivery from './delivery';
import menu from './menu';
import promotion from './promotion';
import configuration from './configuration';

export default {
  auth,
  diner,
  category,
  store,
  cart,
  orderinfo,
  delivery,
  menu,
  promotion,
  configuration,
};
