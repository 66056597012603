import React from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { isEmpty, cloneDeep, findIndex, groupBy, isEqual } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import formatCurrency from 'utils/formatCurrency';
import { mapItemToCart } from 'status/partial/cart';
import { getDefaultMenuItem } from 'status/partial/menu';
import { getTotalCartItemDiscount } from 'utils/getCartAmount';
import styles from './styles.js';

function extraIndex(list, target) {
  return findIndex(list, ext => ext.extra.id === target.id);
}

function groupByPropertyKeyValue(keyList, valueList) {
  const groupedValue = groupBy(valueList, 'property_key');
  return keyList.map(item => ({
    ...item,
    values: groupedValue[item.id],
  }));
}

function getDefaultAttachProperties(attachProps, attachPropValues) {
  const groupedList = groupByPropertyKeyValue(attachProps, attachPropValues);
  const result = groupedList.map(item => ({
    id: item.id,
    key: item.name,
    value: item.values[0].value,
  }));
  return result;
}

function getCurrentAttachValue(attachProp, selectedAttachPropKV) {
  const targetIndex = findIndex(selectedAttachPropKV, kv => kv.id === attachProp.id);
  if (targetIndex >= 0) {
    return selectedAttachPropKV[targetIndex].value;
  }
  return '';
}

function isContainSkuProperty(keyItem, valueItem, skuProperty) {
  const targetKV = {
    key: keyItem.name,
    value: valueItem.value,
  };
  const result = findIndex(skuProperty, item => isEqual(item, targetKV));
  return result >= 0;
}

class ExtrasModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = this.mapItemToCart();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(mapItemToCart(
      nextProps.merchandise.merchandisespec_set[0],
      nextProps.merchandise.store_category,
      getDefaultAttachProperties(
        nextProps.merchandise.attach_properties,
        nextProps.merchandise.attach_property_values,
      ),
    ));
  }

  onClose = () => {
    const { onClose } = this.props;
    this.setState(this.mapItemToCart());
    onClose();
  }

  selectProperty(keyItem, valueItem) {
    const { merchandise } = this.props;
    const { sku } = this.state;
    const targetKV = {
      key: keyItem.name,
      value: valueItem.value,
    };
    const targetPropIndex = findIndex(sku.properties, prop => prop.key === targetKV.key);
    const newProp = cloneDeep(sku.properties);
    newProp[targetPropIndex] = targetKV;
    const targetSkuIndex = findIndex(
      merchandise.merchandisespec_set,
      item => isEqual(item.properties, newProp),
    );
    if (targetSkuIndex === -1) {
      message.error('This spec cant choose now.');
      return;
    }
    this.setState({
      sku: merchandise.merchandisespec_set[targetSkuIndex],
    });
  }

  selectAttachProperty(e, attachProp) {
    const { attachProperties } = this.state;
    const targetIndex = findIndex(attachProperties, kv => kv.id === attachProp.id);
    const newAttachProp = cloneDeep(attachProperties[targetIndex]);
    newAttachProp.value = e.target.value;
    const newSelectedAttachProperties = attachProperties.slice();
    newSelectedAttachProperties[targetIndex] = newAttachProp;
    this.setState({
      attachProperties: newSelectedAttachProperties,
    });
  }

  addExtra = (extra) => {
    const { extras } = this.state;
    const targetIndex = extraIndex(extras, extra);
    if (targetIndex >= 0) {
      const newExtras = cloneDeep(extras);
      newExtras[targetIndex].count += 1;
      this.setState({
        extras: newExtras,
      });
      return;
    }
    this.setState({
      extras: extras.concat({
        extra,
        count: 1,
      }),
    });
  }

  removeExtra = (extra) => {
    const { extras } = this.state;
    const targetIndex = extraIndex(extras, extra);
    if (targetIndex < 0) {
      return;
    }
    const newExtras = cloneDeep(extras);
    const currentCount = newExtras[targetIndex].count;
    if (currentCount <= 1) {
      newExtras.splice(targetIndex, 1);
    } else {
      newExtras[targetIndex].count -= 1;
    }
    this.setState({
      extras: newExtras,
    });
  }

  mapItemToCart = () => {
    const item = this.props.merchandise;
    return {
      ...mapItemToCart(
        item.merchandisespec_set[0],
        item.store_category,
        getDefaultAttachProperties(item.attach_properties, item.attach_property_values),
      ),
    };
  }

  submit = () => {
    const { add } = this.props;
    const data = cloneDeep(this.state);
    if (data.count > 0) {
      add(data);
    }
    // this.onClose();
  }

  render() {
    const { open, onClose, title, classes, merchandise, promotion } = this.props;
    const { extras, count, sku, attachProperties } = this.state;
    return (
      <Dialog
        open={open}
        onClose={onClose}
        classes={{
          paper: classes.paper,
        }}
      >
        {
          !isEmpty(title) &&
          <DialogTitle classes={{ root: classes.title }}>
            {title}
          </DialogTitle>
        }
        <DialogContent className={classes.content}>
          {
            merchandise.property_values.length > 1 && groupByPropertyKeyValue(
              merchandise.properties,
              merchandise.property_values,
            ).map(item => (
              <React.Fragment key={item.id}>
                <Typography variant="button" gutterBottom>{item.name}</Typography>
                <div className={classes.propertyWrap}>
                  {
                    item.values.map(valueItem => (
                      <React.Fragment key={valueItem.id}>
                        {
                          isContainSkuProperty(item, valueItem, sku.properties) ?
                            <Button variant="outlined" size="small" color="primary">{valueItem.value}</Button>
                            :
                            <Button variant="outlined" size="small" onClick={() => this.selectProperty(item, valueItem)}>{valueItem.value}</Button>
                        }
                      </React.Fragment>
                    ))
                  }
                </div>
              </React.Fragment>
            ))
          }
          {
            merchandise.attach_property_values.length > 1 && groupByPropertyKeyValue(
              merchandise.attach_properties,
              merchandise.attach_property_values,
            ).map(item => (
              <FormControl
                className={classes.attach_properties}
                key={item.id}
              >
                <InputLabel>{item.name}</InputLabel>
                <Select
                  value={getCurrentAttachValue(item, attachProperties)}
                  onChange={e => this.selectAttachProperty(e, item)}
                >
                  {
                    item.values.map(valueItem => (
                      <MenuItem
                        value={valueItem.value}
                        key={valueItem.id}
                      >
                        {valueItem.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            ))
          }
          {
            !isEmpty(merchandise.extras) &&
            <div className={classes.extrasWrap}>
              <Table className={classes.extrasTable}>
                <TableHead>
                  <TableRow>
                    <TableCell>Extras</TableCell>
                    <TableCell numeric>Pris</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {merchandise.extras.map(item => (
                    <TableRow key={`extra_${item.id}`}>
                      <TableCell component="th" scope="row" className={classes.cell}>
                        {item.name}
                      </TableCell>
                      <TableCell className={classes.cell} numeric>
                        {formatCurrency(item.price)}
                      </TableCell>
                      {
                        count === 0 ?
                          <TableCell className={classes.cell} numeric>
                            <IconButton
                              className={classes.iconWrapDisabled}
                            >
                              <RemoveCircleIcon />
                            </IconButton>
                            <span>
                              { 0 }
                            </span>
                            <IconButton
                              className={classes.iconWrapDisabled}
                            >
                              <AddCircleIcon />
                            </IconButton>
                          </TableCell>
                          :
                          <TableCell className={classes.cell} numeric>
                            <IconButton
                              className={classes.iconWrap}
                              onClick={() => this.removeExtra(item)}
                            >
                              <RemoveCircleIcon />
                            </IconButton>
                            <span>
                              {
                                extraIndex(extras, item) === -1 ?
                                  0
                                  :
                                  extras[extraIndex(extras, item)].count
                              }
                            </span>
                            <IconButton
                              className={classes.iconWrap}
                              onClick={() => this.addExtra(item)}
                            >
                              <AddCircleIcon />
                            </IconButton>
                          </TableCell>
                      }
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          }
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Typography variant="button" className={classes.totalPrice}>
            DKK {formatCurrency(getTotalCartItemDiscount(this.state, promotion, new Date()))}
          </Typography>
          <Button onClick={this.onClose} color="primary">
            CLOSE
          </Button>
          <Button onClick={this.submit} variant="raised" color="primary" autoFocus>
            ADD TO CART
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ExtrasModal.propTypes = {
  onClose: PropTypes.func,
  add: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  classes: PropTypes.shape().isRequired,
  merchandise: PropTypes.shape({
    extras: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    merchandisespec_set: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    properties: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    attach_properties: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    property_values: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    attach_property_values: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    store_category: PropTypes.shape().isRequired,
  }).isRequired,
  promotion: PropTypes.arrayOf(PropTypes.shape()),
};

ExtrasModal.defaultProps = {
  onClose: () => null,
  add: () => null,
  open: false,
  title: '',
  merchandise: getDefaultMenuItem(),
  promotion: [],
};

export default withStyles(styles)(ExtrasModal);
